export default {
  about: 'About',
  account: 'Account',
  add: 'Add',
  add_contact_or_create_group: 'Add contact or create group',
  alternatively: 'Alternatively',
  appearance: 'Appearance',
  application_security_warning:
    "The application is an unaudited proof-of-concept implementation,  so don't use it for security critical purposes.",
  autoplay_videos: 'Autoplay videos',
  back: 'Back',
  block: 'Block',
  blocked: 'Blocked',
  blocked_users: 'Blocked Users',
  cancel: 'Cancel',
  color_scheme: 'Appearance',
  connected_relays: 'Connected Relays',
  copied: 'Copied',
  copy: 'Copy',
  copy_ID: 'Copy ID',
  copy_link: 'Copy Link',
  copy_raw_data: 'Copy Raw Data',
  copy_user_ID: 'Copy User ID',
  create: 'Create',
  dark: 'Dark',
  delete: 'Delete',
  dm_privacy_warning:
    "Nostr private messages are encrypted, but everyone can see who you're chatting with",
  done: 'Done',
  edit_profile: 'Edit Profile',
  embeds: 'Embeds',
  everyone: 'Everyone',
  follow_all: 'Follow All',
  follow_btn: 'Follow',
  follow_someone_info: 'Follow someone to see content from their network! Suggestions:',
  followers: 'Followers',
  following: 'Following',
  following_btn: 'Following',
  follows: 'Follows',
  follows_you: 'Follows you',
  friday: 'Friday',
  friends_following: 'Friends following',
  get_notified_new_messages: 'Get notified of new messages',
  give_your_profile_link_to_someone: 'give your profile link to someone',
  global_feed: 'Global',
  group_name: 'Group name',
  have_someones_invite_link: "Have someone's invite link?",
  install_lightning_wallet_prompt:
    'Please install a lightning wallet such as getalby.com (desktop) or walletofsatoshi.com (mobile)!',
  language: 'Language',
  last_active: 'last active',
  light: 'Light',
  likes: 'Likes',
  log_out: 'Log out',
  logout_confirmation_info:
    'You <b>cannot log in again</b> unless you have saved a copy of your private key.',
  looking_up_message: 'Looking up message...',
  media: 'Media',
  messages: 'Messages',
  monday: 'Monday',
  network: 'Network',
  new_chat: 'New chat',
  new_group: 'New group',
  new_relay_url: 'New relay URL',
  new_user_go: 'Go',
  no_followers_yet: 'Share your profile link so others can follow you:',
  no_followers_yet_info:
    'Your posts, replies and likes are only shown to your followers and their network.',
  no_notifications_yet: 'No notifications yet',
  none: 'None',
  nostr_extension_login: 'Nostr extension login',
  note_to_self: 'Note to Self',
  now: 'now',
  online: 'online',
  otherwise_cant_log_in_again: "Otherwise you can't log in back to this account.",
  paste_private_key: 'Paste a private key',
  paste_their_invite_link: 'Paste their invite link',
  peers: 'Peers',
  playback: 'Playback',
  post: 'Post',
  posts: 'Posts',
  private_key: 'Private key',
  private_key_login: 'Sign in with existing account',
  private_key_not_present_good: 'Not present. Good!',
  private_key_warning:
    "<b>DANGER!</b> Private key <b>gives access to your account</b>. Don't give or show your private key to anyone else!",
  profile: 'Profile',
  public_key: 'Public key',
  remove: 'Remove',
  remove_attachment: 'Remove attachment',
  replies: 'Replies',
  replying_to: 'Replying to',
  report_public: 'Report (public)',
  reported: 'Reported',
  reposted: 'reposted',
  resend_to_relays: 'Resend to relays',
  restore_defaults: 'Restore defaults',
  saturday: 'Saturday',
  save_backup_of_privkey_first: 'Save a backup of your private key first!',
  save_relays_publicly: 'Save relays publicly',
  scan_qr_code: 'Scan QR code',
  search: 'Search',
  search_posts: 'Search posts',
  send_message: 'Send message',
  settings: 'Settings',
  show_attachment: 'Show Attachment',
  show_beta_features: 'Show Beta Features',
  show_files: 'Show files',
  show_more: 'Show more',
  show_n_new_messages: 'Show {n} new messages',
  show_or_scan_QR_code: 'Show or scan QR code',
  show_qr_code: 'Show QR code',
  show_thread: 'Show thread',
  sunday: 'Sunday',
  switch_account: 'Switch account',
  system_default: 'System default',
  thursday: 'Thursday',
  tip_lightning: 'Tip',
  today: 'today',
  translate: 'Translate',
  tuesday: 'Tuesday',
  turn_on_desktop_notifications: 'Turn on desktop notifications',
  type_a_message: 'Type a message',
  typing: 'Typing...',
  unblock: 'Unblock',
  unfollow_btn: 'Unfollow',
  unreport: 'Unreport',
  use_picture: 'Use picture',
  video_call: 'Video call',
  view_as: 'View as',
  visibility: 'Your posts, replies and likes are only shown to your followers and their network.',
  wednesday: 'Wednesday',
  whats_on_your_mind: "What's on your mind?",
  whats_your_name: "What's your name?",
  write_your_reply: 'Write your reply',
  yesterday: 'yesterday',
  you: 'You',
  your_qr_code: 'Your QR code',
};
